'use client'

import { Book, BookStatistic, Bookmark, Genre } from '@prisma/client'
import { useSession } from 'next-auth/react'
import { Fragment, memo, useEffect, useMemo } from 'react'
import { EnCardName } from 'src/enums/card-name.enum'
import { BookCard } from '../card/BookCard'
import { CatalogCard } from '../card/CatalogCard'
import { UpdateCard } from '../card/UpdateCard'

import { useInfiniteQuery } from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'
import { serverFetch } from 'src/app/lib/serverFetch'
import { Icon } from 'src/components/common/Icon'
import { LoadMore } from '../LoadMore'

export const BookGrid = memo(function BookGrid(props: {
  books: (Book & {
    bookmark?: Bookmark
    genres?: Genre[]
    bookStatistic?: Omit<BookStatistic, 'rate'> & { rate: number }
  })[]
  cardName: EnCardName
  limit: number
  url: string
  queryParams?: string
  className?: string
  isInfinity?: boolean
}) {
  const { ref, inView } = useInView({ rootMargin: '100px' })
  const { url = '/books', isInfinity = false } = props
  const { data: session } = useSession()

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ['books'],
      queryFn: async ({ pageParam }) => {
        const queryParams = [props.queryParams, `page=${pageParam}`]
          .filter(Boolean)
          .join('&')

        const data: (Book & { bookmarks?: Bookmark[] })[] =
          pageParam === 0
            ? props.books
            : await serverFetch<(Book & { bookmarks?: Bookmark[] })[]>({
                url: `${url}?${queryParams}`,
              })

        return {
          data: pageParam === 0 ? [] : data,
          nextId: data && data.length >= props.limit ? pageParam + 1 : null,
          previousId: pageParam >= 1 ? pageParam - 1 : null,
        }
      },
      initialPageParam: 0,
      getPreviousPageParam: (firstPage) => firstPage?.previousId ?? undefined,
      getNextPageParam: (lastPage) => lastPage?.nextId ?? undefined,
    })

  useEffect(() => {
    if (inView && isInfinity) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView, isInfinity])

  const renderCard = (book: Book, position: number) => {
    switch (props.cardName) {
      case EnCardName.UPDATE_CARD:
        return (
          <UpdateCard
            key={book.id}
            isUserAuth={session !== null}
            book={JSON.stringify(book)}
          />
        )

      case EnCardName.CATALOG_CARD:
        return (
          <CatalogCard
            key={book.id}
            isUserAuth={session !== null}
            book={book}
          />
        )

      case EnCardName.SEARCH_CARD:
        return (
          <CatalogCard
            key={book.id}
            isUserAuth={session !== null}
            imageClassName="!h-[calc((100vw_-_32px)_/_3_*_1.5)] md:!h-[calc((100vw_-_84px)_/_6_*_1.5)] xl:!h-[300px]"
            book={book}
          />
        )

      case EnCardName.CATEGORY_CARD:
        return (
          <BookCard key={book.id} isUserAuth={session !== null} book={book} />
        )

      case EnCardName.TOP_CARD:
        return (
          <BookCard
            key={book.id}
            isUserAuth={session !== null}
            book={book}
            position={position}
          />
        )

      default: {
        return null
      }
    }
  }

  const infinityList = useMemo(() => {
    if (data?.pages) {
      return data.pages.flatMap((page) => page.data.map((el) => el))
    }

    return []
  }, [data?.pages])

  const books = useMemo(() => props.books, [props.books])

  return (
    <Fragment>
      <div className={props.className}>
        {books.map((book, key) => {
          return renderCard(book, key + 1)
        })}

        {infinityList.map((book, key) => {
          return renderCard(book, key + props.books.length)
        })}
      </div>

      <button
        className="grid m-auto my-4"
        ref={ref}
        onClick={() => fetchNextPage()}
        disabled={!hasNextPage || isFetchingNextPage}
      >
        {isFetchingNextPage && isInfinity && (
          <Icon
            name="shuriken"
            className="text-primary animate-spin !w-8 !h-8 mx-auto"
          />
        )}

        {!isInfinity && hasNextPage && (
          <LoadMore isLoading={isFetchingNextPage} />
        )}
      </button>
    </Fragment>
  )
})
